import { Route, Routes } from "react-router-dom"
import { ShopsListPage } from "./shops-list"
import { ShopEditPage } from "./shop-edit"

export * from "./home"

export const Shops = () => {
    return (
        <Routes>
            <Route index element={<ShopsListPage />} />
            <Route path='/:shopId' element={<ShopEditPage />} />
            <Route path='/:shopId/:productId' element={<>Product edit</>} />
        </Routes>
    )
}