import { Button } from "@/shared/ui/button"
import { Card } from "@/shared/ui/card"
import { Headline } from "@/shared/ui/headline"
import { Modal } from "@/shared/ui/modal"

import { PlusCircleIcon, ShoppingBagIcon } from "lucide-react"
import { Link } from "react-router-dom"


const SHOPS = [
    {
        name: "Shop name",
        slig: 'shop.slug',
        description: "Small shop description for display on cluent side and admin panel"
    },
    {
        name: "Shop name",
        slig: 'shop.slug',
        description: "Small shop description for display on cluent side and admin panel"
    },
    {
        name: "Shop name",
        slig: 'shop.slug',
        description: "Small shop description for display on cluent side and admin panel"
    },
    {
        name: "Shop name",
        slig: 'shop.slug',
        description: "Small shop description for display on cluent side and admin panel"
    },
    {
        name: "Shop name",
        slig: 'shop.slug',
        description: "Small shop description for display on cluent side and admin panel"
    }
]

export const ShopsListPage = () => {
    return (
        <>
            {/* <Empty 
                title="You don't have shops yet" 
                description="it's time to create your first one, it's free"
            >
                <Modal
                    title={"Creating new shop"}
                    description={"Blah blah blah create...."} 
                    renderContent={() => <>Create new shop</>}
                >
                    <Button className="mt-8">
                        <PlusCircleIcon className="mr-2" size={18}/> Create first shop
                    </Button>
                </Modal>
            </Empty> */}
            <Headline 
                title="Your shops"
                className="py-10"
                description="The maximum number of shops for your tariff is 5"
            >
                <Modal
                    title={"Creating new shop"}
                    description={"Blah blah blah create...."} 
                    renderContent={() => <>Create new shop</>}
                >
                    <Button>
                        <PlusCircleIcon className="mr-2" size={18} />New shop
                    </Button>
                </Modal>
            </Headline>
            <div className="grid grid-cols-3 gap-3">
                { SHOPS.map(shop => (
                    <Link to={shop.slig} className="block">
                    <Card className="transition-all hover:shadow-lg">
                        <div>
                            <div className="flex pb-3">
                                <div className="p-3 mr-4 rounded-full bg-zinc-100 text-black">
                                    <ShoppingBagIcon />
                                </div>
                                <div>
                                    <h4 className="font-semibold">{shop.name}</h4>
                                    <div className="text-[12px]">{shop.slig}.deally.me</div>
                                </div>
                            </div>
                            <div className="pt-3 text-muted-foreground text-[14px]">{shop.description}...</div>
                            {/* <Badge variant={"secondary"}><ClockIcon size={14} className="mr-1" /> 12.03.2023 17:56</Badge> */}
                        </div>
                    </Card>
                    </Link>
                    ))}
            </div> 
        </>
    )
}