import { Button } from "@/shared/ui/button"
import { Input } from "@/shared/ui/input"
import { Modal } from "@/shared/ui/modal"
import { Textarea } from "@/shared/ui/textarea"
import {ArrowLeftIcon, CopyIcon, PlusCircleIcon, TrashIcon, ViewIcon } from "lucide-react"
import { ProductsTable } from "./products-table"
import { useNavigate } from "react-router-dom"
import { Headline } from "@/shared/ui/headline"
import { toast } from "sonner"
import { Toggle } from "@/shared/ui/toggle"

const GoBack = () => {
    const navigate = useNavigate()
    const handleClick = () => navigate(-1)

    return (
        <Button onClick={() => handleClick()} variant={"link"} className="flex items-center mt-10 mb-10">
            <ArrowLeftIcon size={18} className="mr-2"/>
             Go back
       </Button>
    )
}

export const ShopEditPage = () => {
    const handleCopyClick = () => {
        toast.success("Shop Name link copied to clipboard")
        const promise = () => new Promise((_, reject) => setTimeout(() => reject({ name: 'Sonner' }), 2000));
        toast.promise(promise, {
            loading: "Saving shop settings",
            success: "Shop settings successfully saved",
            error: "Shop settings cannot be saved"
        })
    }

 return (
    <> 
        <GoBack />
        <div className="bg-white border rounded">
            <Headline
                title="Shop name"
                description="deally.deally.me"
                className="p-10 border-b"
            >
                <div className="space-x-2">
                    <Button onClick={() => handleCopyClick()} variant={"ghost"}>
                        <CopyIcon size={18} className="mr-2"/> Copy shop link
                    </Button>
                    <Button variant={"outline"}>
                        <ViewIcon size={18} className="mr-2" /> View shop
                    </Button>
                    <Button variant={"outline"} className="text-red-500">
                        <TrashIcon size={18} className="mr-2" /> Delete shop
                    </Button>
                </div>
            </Headline>
       <div className="flex">
            <div className="min-w-[420px] p-10 space-y-4 border-r">
                <Headline 
                    title="Settings" 
                    description="Changes made will be saved automatically" 
                />
                {/* Common section */}
                <Input placeholder="Shop name" />
                <Textarea className="resize-none" placeholder="Shop description"/>
                {/* Payments section */}
                <div>
                    <h3 className="text-lg font-medium">Payments methods</h3>
                    <p className="text-sm text-muted-foreground">This settings apply to all products.</p>
                </div>
                <div>
                    <div className="space-y-2 flex flex-row items-center justify-between rounded-lg border p-4">
                        <div className="space-y-0.5 w-full">
                            <label className="font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-base" htmlFor=":r2a:-form-item">Paypal</label>
                            <p id=":r2a:-form-item-description" className="text-[0.8rem] text-muted-foreground block w-[320px]">The commission is 2% of the payment, the money will go directly to your account</p>
                        </div>
                        <Toggle />
                    </div>
                </div>
                <div className="space-y-2 flex flex-row items-center justify-between rounded-lg border p-4">
                    <div className="space-y-0.5 w-full">
                        <label className="font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-base" htmlFor=":r2a:-form-item">Bank card</label>
                        <p id=":r2a:-form-item-description" className="text-[0.8rem] text-muted-foreground block w-[320px]">Visa and Master card, money will be credited to your account deally, withdrawal conditions:</p>
                    </div>
                    <Toggle />
                </div>
            </div>
            <div className="w-full flex-1 bg-white p-10 rounded">
                <div className="pb-10">
                    <Headline 
                        title="Products" 
                        description="The maximum number of products for this shop is 100"
                    >
                        <Modal
                            title="Product creating"
                            description="sgdfs"
                            renderContent={() => <span>Content</span>}
                        >
                            <Button>
                                <PlusCircleIcon className="mr-2" size={18} /> Create product
                            </Button>
                        </Modal>
                    </Headline>
                </div>
                <ProductsTable />
            </div>
       </div>
       </div>
    </>
 )   
}