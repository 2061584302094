import { Route, Routes } from "react-router-dom"

export * from "./profile"

export const Profile = () => {
    return (
        <Routes>
            <Route index element={<>Profile home</>} />
            <Route path='billing' element={<>Billing info</>} />
            <Route path='withdrawal' element={<>Withdrawal</>} />
        </Routes>
    )
}