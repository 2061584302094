import { FC, PropsWithChildren } from "react"
import { cn } from "../lib"

type HeadlineProps = {
    title: string
    description?: string
    className?: string
} & PropsWithChildren

export const Headline: FC<HeadlineProps> = ({ title, description, children, className }) => (
    <div className={cn('flex justify-between content-center', className)}>
        <div>
            <h2 className="text-2xl font-bold tracking-tight">{title}</h2>
            {!!description && <p className="text-muted-foreground">{description}</p>}
        </div>
        { children }
    </div>
)