import { PropsWithChildren } from "react";
import { cn } from "@/shared/lib"
import { Avatar, AvatarFallback, AvatarImage } from "@/shared/ui/avatar"
import { Button } from "@/shared/ui/button"
import { NavigationMenuItem, NavigationMenuLink, NavigationMenuList, navigationMenuTriggerStyle } from "@/shared/ui/navigation-menu"
import { PopoverContent, PopoverTrigger } from "@/shared/ui/popover"
import { NavigationMenu } from "@radix-ui/react-navigation-menu"
import { Popover } from "@radix-ui/react-popover"
import { ChevronDown, ShoppingBagIcon, UserIcon } from "lucide-react"
import { FC, useState } from "react"
import { Link, Outlet } from "react-router-dom"

const Logotype = () => {
    return (
        <div className="flex lowercase font-semibold"><ShoppingBagIcon className="mr-2" /> deally.me/dashboard</div>
    )   
}
const User = () => {
    const [isOpen, setIsOpen] = useState(false);
    
    return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild>
        <div className="flex items-center">
            <Avatar className="cursor-pointer mr-2">
                <AvatarImage src="https://github.com/shadcn.png" />
                <AvatarFallback>CN</AvatarFallback>
            </Avatar>
            <ChevronDown size={18} className={isOpen ? "scale-1" : ""} />
        </div>
        </PopoverTrigger>
        <PopoverContent align="end" className="w-80">
            <div className="border-b pb-3">
                <div className="pl-0 pt-0">
                    <div className="pl-0 flex flex-row items-center justify-between space-y-0 pb-0">
                        <h3 className="tracking-tight text-sm font-medium">Available for withdrawal</h3>
                    </div>
                    <div className="text-xl font-bold">$45,231.89</div>
                    <div className="text-muted-foreground text-sm">Updated at: 21.02.2023 03:00</div>
                </div>
                <div className="pt-2">
                    <Button size="sm" variant="outline" className="w-full">Create payout request</Button>
                </div>
            </div>
            <NavigationMenu orientation="vertical">
                <NavigationMenuList>
                    <NavigationMenuItem>
                        <Link to={"/"} className="w-full">
                            <NavigationMenuLink className={cn(navigationMenuTriggerStyle(), "w-full")}>
                                <UserIcon className="mr-2 h-4 w-4" />
                                <span>Profile</span>
                            </NavigationMenuLink>
                        </Link>
                    </NavigationMenuItem>
                </NavigationMenuList>
            </NavigationMenu>
        </PopoverContent>
    </Popover>
)}

export const Layout: FC<PropsWithChildren> = () => (
<div className="bg-zinc-50 min-h-[100svh]">
    <header className="sticky left-0 w-full top-0 bg-white">
        <div className="border-b">
            <div className="container py-4 flex justify-between items-center">
                <div>
                    <Logotype />
                </div>
                <div className="flex gap-5">
                    <div className="pl-0 pt-0">
                        <div className="pl-0 flex flex-row items-center justify-between space-y-0 pb-0">
                            <h3 className="tracking-tight text-xs font-medium">Current balance</h3>
                        </div>
                        <div className="text-m font-bold">$45,231.89 + <span className="text-green-500">$324.05</span></div>
                    </div>
                    <User />
                </div>
            </div>
        </div>
    </header>
    <main className="container mx-auto py-0">
        <Outlet />
    </main>
</div>
)
