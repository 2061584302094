import './App.css';
import { Auth } from "@/pages/auth"
import "./globals.scss"
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import "non.geist"
import { Shops } from '@/pages/shops';
import { Toaster } from 'sonner';
import { Layout } from '@/widgets/layout';
import { Profile } from '@/pages/profile';
import { useEffect } from 'react';

const AppRouter = () => { 
  const isAuthorized = true;
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const hasLoginInRoute = location.pathname.includes('login')

    if (isAuthorized && hasLoginInRoute) navigate('/')
    if (!isAuthorized && !hasLoginInRoute) navigate('/login')
  }, [location.pathname, navigate, isAuthorized])

  return (
    <Routes>
      { !isAuthorized && (
          <Route path='/login/*' element={<Auth />} />
      )}
      { isAuthorized && (
        <Route element={<Layout />}>
          <Route path='/*' element={<Shops />} />
          <Route path='/profile/*' element={<Profile />} />
        </Route>
      )}
    </Routes>
  )
}

function App() {

  return (
    <>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
      <Toaster />
    </>
  );
}

export default App;
