import { FC, PropsWithChildren, ReactElement, useState } from "react"
import { useMediaQuery } from 'usehooks-ts'

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "./dialog"
  import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
  } from "./drawer"

  type ModalProps = {
    title: string,
    renderContent: () => ReactElement
    description?: string
  } & PropsWithChildren
  export const Modal: FC<ModalProps> = ({ children, title, renderContent, description })  => {
    const [open, setOpen] = useState(false)
    const isDesktop = useMediaQuery("(min-width: 768px)")
   
    if (isDesktop) {
      return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                { children }
            </DialogTrigger>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>{title}</DialogTitle>
              {!!description && <DialogDescription>{description}</DialogDescription> }
            </DialogHeader>
            { renderContent() }
          </DialogContent>
        </Dialog>
      )
    }
   
    return (
      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerTrigger asChild>
            { children }
        </DrawerTrigger>
        <DrawerContent>
          <DrawerHeader className="text-left">
            <DrawerTitle>Edit profile</DrawerTitle>
            <DrawerDescription>
              Make changes to your profile here. Click save when you're done.
            </DrawerDescription>
          </DrawerHeader>
          { renderContent() }
        </DrawerContent>
      </Drawer>
    )
  }
  